import * as React from 'react'
import { Box } from '@chakra-ui/react'
import GroupsPage from '../pagesComponents/groups'
import Dashboard from './dashboard'
import Layout from '../layout'
import Base from '../base'

const App = () => {
	return (
		<Layout theme="light" title="Dashboard">
			<Box display={{ lg: 'block', base: 'none' }}>
				<GroupsPage access={5} theme="dark" parent="dashboard" />
			</Box>
			<Box display={{ lg: 'none', base: 'block' }}>
				<Base access={5} theme="light" parent="dashboard">
					<Dashboard />
				</Base>
			</Box>
		</Layout>
	)
}

export default App
